import React, { useState } from 'react'
import AliceCarousel from 'react-alice-carousel'
import ProductCard from '../homeproductcard/HomeProductCard';
import { Button } from '@mui/material';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./HomeProductCarousel.css";
import { useNavigate } from 'react-router-dom';
import wood_lam from "./solid lam.png";
import marble_lam from "./marble_lam.png";
import solid_lam from "./solid_lam.png";
import fluted_lam from "./fluted_lam.png";
import wallpaper_lam from "./wallpaper_lam.png";
import kitchen_lam from "./kitchen_preview.png";
import bathroom_lam from "./bathroom_preview.png";
import wardrobe_lam from "./wardrobe_preview.png";
import outdoor_lam from "./outdoor_preview.png";
import flooring_lam from "./flooring_preview.png";

const ProductCarousel = ({data}) => {

  const [activeIndex, setActiveIndex] = useState(0);

  const slidePrev = () => setActiveIndex(activeIndex - 1);
  const slideNext = () => setActiveIndex(activeIndex + 1);
  const syncActiveIndex = ({ item }) => setActiveIndex(item);

  const navigate = useNavigate();

  const responsive = {
    0: {
      items: 2,
      itemsFit: "contain",
    },
    568: {
      items: 3,
      itemsFit: "contain",
    },
    1024: {
      items: 5.5,
      itemsFit: "contain",
    },
  };
  // const items = data?.slice(0, 5).map((item) => (
  //   <div className="ml-auto flex items-center justify-center">
  //     {" "}
  //     <ProductCard product={item} />
  //   </div>
  // ));

    return (

      <div>

        <div className='flex items-center justify-center'>
          <div
            onClick={() => navigate(`/laminates/shop_by_look/wood_laminates`)}
            className="cursor-pointer flex flex-col items-center bg-white rounded-lg shadow-lg overflow-hidden w-[15rem] mx-3"
          >
            <div className="h-[13rem] w-[10rem]">
              <img
                className="object-cover object-top w-full h-full"
                src={wood_lam}
                alt=""
              />
            </div>

            <div className="p-4 ">
              <h3 className="text-lg font-medium text-gray-900">
              Wood Laminates
              </h3>
              {/* <p className="mt-2 text-sm text-gray-500">product title</p> */}
            </div>
          </div>

          <div
            onClick={() => navigate(`/laminates/shop_by_look/marble_and_stone_laminates`)}
            className="cursor-pointer flex flex-col items-center bg-white rounded-lg shadow-lg overflow-hidden w-[15rem] mx-3"
          >
            <div className="h-[13rem] w-[10rem]">
              <img
                className="object-cover object-top w-full h-full"
                src={marble_lam}
                alt=""
              />
            </div>

            <div className="p-4 ">
              <h3 className="text-lg font-medium text-gray-900">
              Marble Laminates
              </h3>
              {/* <p className="mt-2 text-sm text-gray-500">product title</p> */}
            </div>
          </div>

          <div
            onClick={() => navigate(`/laminates/shop_by_look/solid_laminates`)}
            className="cursor-pointer flex flex-col items-center bg-white rounded-lg shadow-lg overflow-hidden w-[15rem] mx-3"
          >
            <div className="h-[13rem] w-[10rem]">
              <img
                className="object-cover object-top w-full h-full"
                src={solid_lam}
                alt=""
              />
            </div>

            <div className="p-4 ">
              <h3 className="text-lg font-medium text-gray-900">
              Solid Laminates
              </h3>
              {/* <p className="mt-2 text-sm text-gray-500">product title</p> */}
            </div>
          </div>

          <div
            onClick={() => navigate(`/laminates/shop_by_look/fluted_laminates`)}
            className="cursor-pointer flex flex-col items-center bg-white rounded-lg shadow-lg overflow-hidden w-[15rem] mx-3"
          >
            <div className="h-[13rem] w-[10rem]">
              <img
                className="object-cover object-top w-full h-full"
                src={fluted_lam}
                alt=""
              />
            </div>

            <div className="p-4 ">
              <h3 className="text-lg font-medium text-gray-900">
              Fluted Laminates
              </h3>
              {/* <p className="mt-2 text-sm text-gray-500">product title</p> */}
            </div>
          </div>

          <div
            onClick={() => navigate(`/men/clothing/mens_kurta`)}
            className="cursor-pointer flex flex-col items-center bg-white rounded-lg shadow-lg overflow-hidden w-[15rem] mx-3"
          >
            <div className="h-[13rem] w-[10rem]">
              <img
                className="object-cover object-top w-full h-full"
                src={wallpaper_lam}
                alt=""
              />
            </div>

            <div className="p-4 ">
              <h3 className="text-lg font-medium text-gray-900">
              Wallpaper
              </h3>
              {/* <p className="mt-2 text-sm text-gray-500">product title</p> */}
            </div>
          </div>
        </div>

        <div className='flex items-center justify-center mt-10'>
          <div
            onClick={() => navigate(`/men/clothing/mens_kurta`)}
            className="cursor-pointer flex flex-col items-center bg-white rounded-lg shadow-lg overflow-hidden w-[15rem] mx-3"
          >
            <div className="h-[13rem] w-[10rem]">
              <img
                className="object-cover object-top w-full h-full"
                src={outdoor_lam}
                alt=""
              />
            </div>

            <div className="p-4 ">
              <h3 className="text-lg font-medium text-gray-900">
              Outdoor
              </h3>
              {/* <p className="mt-2 text-sm text-gray-500">product title</p> */}
            </div>
          </div>

          <div
            onClick={() => navigate(`/men/clothing/mens_kurta`)}
            className="cursor-pointer flex flex-col items-center bg-white rounded-lg shadow-lg overflow-hidden w-[15rem] mx-3"
          >
            <div className="h-[13rem] w-[10rem]">
              <img
                className="object-cover object-top w-full h-full"
                src={kitchen_lam}
                alt=""
              />
            </div>

            <div className="p-4 ">
              <h3 className="text-lg font-medium text-gray-900">
              Kitchen
              </h3>
              {/* <p className="mt-2 text-sm text-gray-500">product title</p> */}
            </div>
          </div>

          <div
            onClick={() => navigate(`/men/clothing/mens_kurta`)}
            className="cursor-pointer flex flex-col items-center bg-white rounded-lg shadow-lg overflow-hidden w-[15rem] mx-3"
          >
            <div className="h-[13rem] w-[10rem]">
              <img
                className="object-cover object-top w-full h-full"
                src={flooring_lam}
                alt=""
              />
            </div>

            <div className="p-4 ">
              <h3 className="text-lg font-medium text-gray-900">
              Flooring
              </h3>
              {/* <p className="mt-2 text-sm text-gray-500">product title</p> */}
            </div>
          </div>

          <div
            onClick={() => navigate(`/men/clothing/mens_kurta`)}
            className="cursor-pointer flex flex-col items-center bg-white rounded-lg shadow-lg overflow-hidden w-[15rem] mx-3"
          >
            <div className="h-[13rem] w-[10rem]">
              <img
                className="object-cover object-top w-full h-full"
                src={bathroom_lam}
                alt=""
              />
            </div>

            <div className="p-4 ">
              <h3 className="text-lg font-medium text-gray-900">
              Bathroom
              </h3>
              {/* <p className="mt-2 text-sm text-gray-500">product title</p> */}
            </div>
          </div>

          <div
            onClick={() => navigate(`/men/clothing/mens_kurta`)}
            className="cursor-pointer flex flex-col items-center bg-white rounded-lg shadow-lg overflow-hidden w-[15rem] mx-3"
          >
            <div className="h-[13rem] w-[10rem]">
              <img
                className="object-cover object-top w-full h-full"
                src={wardrobe_lam}
                alt=""
              />
            </div>

            <div className="p-4 ">
              <h3 className="text-lg font-medium text-gray-900">
              Wardrobe
              </h3>
              {/* <p className="mt-2 text-sm text-gray-500">product title</p> */}
            </div>
          </div>
        </div>

      </div>

      


      // <div className="relative px-4 sm:px-6 lg:px-8  ">
        
      //   <div className="relative border p-5">
      //     <AliceCarousel
      //       disableButtonsControls
      //       disableDotsControls
      //       mouseTracking
      //       items={items}
      //       activeIndex={activeIndex}
      //       responsive={responsive}
      //       onSlideChanged={syncActiveIndex}
      //       animationType="fadeout"
      //       animationDuration={2000}
      //     />
      //       {activeIndex !== items.length - 5 && (
      //           <Button
      //               onClick={slideNext}
      //               variant="contained"
      //               className="z-50 bg-[] shadow-lg border"
      //               sx={{
      //               position: "absolute",
      //               top: "8rem",
      //               right: "0rem",
      //               transform: "translateX(50%) rotate(90deg)",
      //               bgcolor: "white",
      //               }}
      //               aria-label="next"
      //           >
      //               <ArrowForwardIosIcon
      //               className=""
      //               sx={{ transform: "rotate(-90deg)", color: "black" }}
      //               />
      //           </Button>
      //       )}

      //       {activeIndex !== 0 && (
      //           <Button
      //               onClick={slidePrev}
      //               variant="contained"
      //               className="z-50 bg-[] shadow-lg border"
      //               sx={{
      //               position: "absolute",
      //               top: "8rem",
      //               left: "0rem",
      //               transform: "translateX(-50%)  rotate(90deg)",
      //               bgcolor: "white",
      //               }}
      //               aria-label="next"
      //           >
      //               <ArrowForwardIosIcon
      //               className=""
      //               sx={{ transform: " rotate(90deg)", color: "black" }}
      //               />
      //           </Button>
      //       )}

      //   </div>
      // </div>
    );
};

export default ProductCarousel