import { useState } from "react";
import { Box, Typography } from "@mui/material";
import {
    Grid,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Snackbar, 
    Alert,
} from "@mui/material";

import { Fragment } from "react";
import "../../../admin/components/createProduct/CreateProductForm.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import FormData from "form-data";
import { bookDemo } from "../../../redux/components/contactForm/Action";

const BookDemo = () => {

    const [productData, setProductData] = useState({
        firstName: "",
        lastName: "",
        companyName: "",
        phone: "",
        email: "",
        city: "",
        productCategory: "",
    });

    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const jwt = localStorage.getItem("jwt")

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProductData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('firstName', productData.firstName);
        formData.append('lastName', productData.lastName);
        formData.append('companyName', productData.companyName);
        formData.append('phone', productData.phone);
        formData.append('email', productData.email);
        formData.append('city', productData.city);
        formData.append('productCategory', productData.productCategory);

        dispatch(bookDemo({ data: formData }))
          .then(() => {
            setSnackbarOpen(true);  // Show Snackbar on successful email sending
          })
          .catch(error => {
            console.error("Error sending email: ", error);
          });
        console.log(formData);
    };


    return (
        <Fragment className="createProductContainer">
            <Typography
                variant="h3"
                sx={{ textAlign: "center" }}
                className="py-10 text-center "
            >
                Book A Demo
            </Typography>
            <form
                onSubmit={handleSubmit}
                encType="multipart/form-data"
                className=""
            >
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={6}>
                        <div className="ml-10 mr-40" style={{ textAlign: 'justify' }}>

                            <p className="text-2xl text-gray-900">Explore ADM's products and services.</p>
                            <br></br>

                            <p>ADM provides architects, engineers, builders, interior designers, and home-owners with design inspiration and digital product information while giving building product manufacturers a robust platform to reach, influence, and understand their target audience.</p>
                            <br></br>
                            <p>Want to learn more? Let us show you around and highlight the demand for your products.</p>
                            <br></br>
                            <p>What to Expect from an ADM Demo:</p>
                            <br></br>
                            <p>- A detailed walk-through of ADM's unique value propositions.</p>
                            <br></br>
                            <p>- A session customized to your needs and interests.</p>
                            <br></br>
                            <p>- Answers to all your questions.</p>

                        </div>
                    </Grid>

                    <Grid item xs={12} sm={6}>

                        <Grid item xs={12} sm={6}>
                            <Box mb={3}>
                                <TextField
                                    fullWidth
                                    label="FirstName"
                                    name="firstName"
                                    value={productData.firstName}
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Box mb={3}>
                                <TextField
                                    fullWidth
                                    label="LastName"
                                    name="lastName"
                                    value={productData.lastName}
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box mb={3}>
                                <TextField
                                    fullWidth
                                    label="Company's Name"
                                    name="companyName"
                                    value={productData.companyName}
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box mb={3}>
                                <TextField
                                    fullWidth
                                    label="Phone Number"
                                    name="phone"
                                    value={productData.phone}
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box mb={3}>
                                <TextField
                                    fullWidth
                                    label="Email Address"
                                    name="email"
                                    value={productData.email}
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box mb={3}>
                                <TextField
                                    fullWidth
                                    label="City"
                                    name="city"
                                    value={productData.city}
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box mb={3}>
                                <TextField
                                    fullWidth
                                    label="Product Category"
                                    name="productCategory"
                                    value={productData.productCategory}
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>

                        {/* <Grid item xs={6} sm={4}>
            <FormControl fullWidth>
              <InputLabel>Product Category</InputLabel>
              <Select
                name="productCategory"
                value={productData.productCategory}
                onChange={handleChange}
                label="Product Category"
              >
                <MenuItem value="laminates">Laminates</MenuItem>
                <MenuItem value="flooring">Flooring</MenuItem>
                <MenuItem value="kitchen">Kitchen</MenuItem>
                <MenuItem value="wardrobe">WardRobe</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}

                        <Grid item xs={12} >
                            <Button
                                variant="contained"
                                sx={{ p: 1.8 }}
                                className="py-20"
                                size="large"
                                type="submit"
                            >
                                Submit
                            </Button>

                        </Grid>

                    </Grid>

                </Grid>
            </form>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
            >
            <Alert onClose={() => setSnackbarOpen(false)} severity="success">
                Email sent successfully!
            </Alert>
            </Snackbar>
        </Fragment>
    );
};

export default BookDemo;
