import api, { API_BASE_URL } from "../../../config/ApiConfig";
import { CREATE_PRODUCT_FAILURE, CREATE_PRODUCT_REQUEST, CREATE_PRODUCT_SUCCESS, DELETE_PRODUCT_FAILURE, DELETE_PRODUCT_REQUEST, DELETE_PRODUCT_SUCCESS, FIND_PRODUCTS_BY_CATEGORY_FAILURE, FIND_PRODUCTS_BY_CATEGORY_REQUEST, FIND_PRODUCTS_BY_CATEGORY_SUCCESS, FIND_PRODUCT_BY_ID_FAILURE, FIND_PRODUCT_BY_ID_REQUEST, FIND_PRODUCT_BY_ID_SUCCESS, UPDATE_PRODUCT_FAILURE, UPDATE_PRODUCT_REQUEST, UPDATE_PRODUCT_SUCCESS } from "./ActionTypes";

export const findProducts = (reqData, jwt) => async (dispatch) => {
    const {
      colors,
      sizes,
      minPrice,
      maxPrice,
      minDiscount,
      category,
      stock,
      sort,
      pageNumber,
      pageSize,
    } = reqData;
  
    try {

      const headers = {};

      if (jwt) {
        headers['Authorization'] = `Bearer ${jwt}`;
      }
  
      const { data } = await api.get(
        `/api/products?color=${colors}&size=${sizes}&minPrice=${minPrice}&maxPrice=${maxPrice}&minDiscount=${minDiscount}&stock=${stock}&category=${category}&sort=${sort}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
        { headers }
      );
  
      console.log("get product by category - ", data);

      dispatch({type: FIND_PRODUCTS_BY_CATEGORY_SUCCESS, payload: data});

    } catch (error) {
      dispatch({type: FIND_PRODUCTS_BY_CATEGORY_FAILURE, payload: error.message});
    }
  };
  
  export const findProductById = (reqData) => async (dispatch) => {
    try {
      dispatch({ type: FIND_PRODUCT_BY_ID_REQUEST });
  
      const { data } = await api.get(`/api/products/id/${reqData.productId}`);
  
      console.log("products by  id : ", data);

      dispatch({
        type: FIND_PRODUCT_BY_ID_SUCCESS,
        payload: data,
      });

    } catch (error) {

      dispatch({
        type: FIND_PRODUCT_BY_ID_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      
    }
  };
  
  export const createProduct = (product) => async (dispatch) => {
    try {
      dispatch({ type: CREATE_PRODUCT_REQUEST });

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
  
      const { data } = await api.post(
        `${API_BASE_URL}/api/admin/products/`,
        product.data,
        config
      );
  
      dispatch({
        type: CREATE_PRODUCT_SUCCESS,
        payload: data,
      });
  
      console.log("created product ", data);
    } catch (error) {
      dispatch({
        type: CREATE_PRODUCT_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const updateProduct = (product, productId) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_PRODUCT_REQUEST });

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
  
      const { data } = await api.put(
        `${API_BASE_URL}/api/admin/products/${productId}/update`,
        product.data,
        config
      );
  
      dispatch({
        type: UPDATE_PRODUCT_SUCCESS,
        payload: data,
      });
      console.log("updated product ", data);
    } catch (error) {
      dispatch({
        type: UPDATE_PRODUCT_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const deleteProduct = (productId) => async (dispatch) => {
    console.log("delete product action",productId)
    try {
      dispatch({ type: DELETE_PRODUCT_REQUEST });
  
      let {data}=await api.delete(`/api/admin/products/${productId}/delete`);
  
      dispatch({
        type: DELETE_PRODUCT_SUCCESS,
        payload: data,
      });
  
      console.log("product delete ",data)
    } catch (error) {
      console.log("catch error ",error)
      dispatch({
        type: DELETE_PRODUCT_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };