import React from 'react'
import { homeCarouselData } from './HomeCarouselData'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import banner_1 from "./Group 3.png";
import banner_2 from "./Banner 2 2.png";
import banner_3 from "./Banner 3 1.png";

const items = [
    <div className="item" data-value="1">
        <img src={banner_1} alt=''></img>
    </div>,
    <div className="item" data-value="2">
        <img src={banner_2} alt=''></img>
    </div>,
    <div className="item" data-value="3">
        <img src={banner_3} alt=''></img>
    </div>,
];

const HomeCarousel = () => {

    //const items = homeCarouselData.map((item)=> <img className='cursor-pointer -z-10' role='presentation' src={item.image} alt=""/>)

  return (
    <AliceCarousel
        items={items}
        disableButtonsControls
        autoPlay
        autoPlayInterval={1000}
        infinite
    />
  )
}

export default HomeCarousel