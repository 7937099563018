import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import StarIcon from "@mui/icons-material/Star";
import { useNavigate, useParams } from "react-router-dom";
import { deepPurple } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import AddressCard from "../addresscard/AddressCard";
import OrderTracker from "./OrderTracker";

const OrderDetails = () => {
  return (

    <div className=" px-2 lg:px-36 space-y-7 ">

      <Grid container className="p-3 shadow-lg">
        <Grid xs={12}>
          <p className="font-bold text-lg py-2">Delivery Address</p>
        </Grid>
        <Grid item xs={6}>
          <AddressCard />
        </Grid>
      </Grid>
      <Box className="p-5 shadow-lg border rounded-md">
        <Grid container sx={{ justifyContent: "space-between", alignItems: "center" }}>
          <Grid item xs={12}>
            <OrderTracker activeStep={3}/>
          </Grid>
        </Grid>
      </Box>

    

      <Grid container className="space-y-5">
        {[1,1,1,1,1].map((item) => (
          <Grid container item className="shadow-xl rounded-md p-5 border" sx={{ alignItems: "center", justifyContent: "space-between" }}>
            <Grid item xs={6}>
              <div className="flex  items-center ">
                <img className="w-[5rem] h-[5rem] object-cover object-top" src="https://rukminim1.flixcart.com/image/612/612/xif0q/kurta/l/f/r/xl-k-spl668-yellow-sg-leman-original-imagznqcrahgq9rf.jpeg?q=70" alt=""/>
                <div className="ml-5 space-y-2">
                  <p className="font-semibold">Men Slim Jeans</p>
                  <p className="opacity-50 text-xs font-semibold space-x-5">
                    <span>Color: pink</span> <span>Size: M</span>
                  </p>
                  <p>Seller: Puma</p>
                  <p>₹1099 </p>
                </div>
              </div>
            </Grid>
            <Grid item>
              {
                <Box
                  sx={{ color: deepPurple[500] }}
                  className="flex items-center cursor-pointer"
                >
                  <StarIcon
                    sx={{ fontSize: "2rem" }}
                    className="px-2 text-5xl"
                  />
                  <span>Rate & Review Product</span>
                </Box>
              }
            </Grid>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};


export default OrderDetails;
