export const color = [
    "white",
    "black",
    "red",
    "marun",
    "beige",
    "pink",
    "green",
    "yellow",
];

export const filters = [
    {
      id: 'color',
      name: 'Color',
      options: [
        { value: 'white', label: 'White', checked: false },
        { value: 'beige', label: 'Beige', checked: false },
        { value: 'blue', label: 'Blue', checked: false },
        { value: 'brown', label: 'Brown', checked: false },
        { value: 'green', label: 'Green', checked: false },
        { value: 'purple', label: 'Purple', checked: false },
      ],
    },
  ];

  export const singleFilter = [
    {
        id: 'price',
        name: 'Price',
        options: [
          { value: '159-399', label: '₹159 to ₹399' },
          { value: '399-999', label: '₹399 to ₹999' },
          { value: '999-1999', label: '₹999 to ₹1999' },
        ],
    },
    {
        id: 'discount',
        name: 'Discount Range',
        options: [
          { value: '10', label: '10% And Above' },
          { value: '20', label: '20% And Above' },
          { value: '40', label: '40% And Above' },
          { value: '50', label: '50% And Above' },
          { value: '60', label: '60% And Above' },
          { value: '70', label: '70% And Above' },
          { value: '80', label: '80% And Above' },
        ],
    },
    {
        id: 'stock',
        name: 'Availability',
        options: [
          { value: 'in_stock', label: 'In Stock' },
          { value: 'out_of_stock', label: 'Out of Stock' },
        ],
    },
  ];