import {applyMiddleware, combineReducers, legacy_createStore} from "redux"
import authReducer from "./auth/Reducer";
import customerProductReducer from "./components/product/Reducer";
import cartReducer from "./components/cart/Reducer";
import { orderReducer } from "./components/order/Reducer";
import { thunk } from "redux-thunk";
import adminOrderReducer from "./Admin/Orders/Reducer";
import productReducer from "./Admin/Product/Reducer";





const rootReducers=combineReducers({

    auth:authReducer,
    customersProduct:customerProductReducer,
    cart:cartReducer,
    order:orderReducer,
    // review:ReviewReducer,

    // admin
    adminsProduct:productReducer,
    adminsOrder:adminOrderReducer,


});

export const store = legacy_createStore(rootReducers,applyMiddleware(thunk))