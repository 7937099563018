import api, { API_BASE_URL } from "../../../config/ApiConfig";
import { SEND_EMAIL_REQUEST, SEND_EMAIL_SUCCESS, SEND_EMAIL_FAILURE } from "./ActionTypes";
  
  export const sendEmail = (formData) => async (dispatch) => {
    try {
      dispatch({ type: SEND_EMAIL_REQUEST });

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };

      const { data } = await api.post(
        `${API_BASE_URL}/api/email/send`,
        formData.data,
        config
      );
  
      dispatch({
        type: SEND_EMAIL_SUCCESS,
        payload: data,
      });
  
      console.log("EMAIL SENT ", data);

    } catch (error) {
      dispatch({
        type: SEND_EMAIL_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const bookDemo = (formData) => async (dispatch) => {
    try {
      dispatch({ type: SEND_EMAIL_REQUEST });

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      
      const { data } = await api.post(
        `${API_BASE_URL}/api/email/book/demo`,
        formData.data,
        config
      );
  
      dispatch({
        type: SEND_EMAIL_SUCCESS,
        payload: data,
      });
  
      console.log("EMAIL SENT ", data);

    } catch (error) {
      dispatch({
        type: SEND_EMAIL_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };