import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import CustomerRoutes from './routers/CustomerRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getUser } from './redux/auth/Action';
import AdminRouters from './routers/AdminRouters';

function App() {

  const {auth}=useSelector(store=>store);
  const dispatch = useDispatch();
  const jwt = localStorage.getItem("jwt");
  useEffect(() => {
    if (jwt) {
      dispatch(getUser(jwt));
    }
  }, [jwt]);

  return (
    <div className="">
      <Routes>
        <Route path='/*' element={<CustomerRoutes/>} />
        {auth.user?.role==="ROLE_ADMIN" && <Route path="/admin/*" element={<AdminRouters />} />}
      </Routes>
    </div>
  );
}

export default App;
