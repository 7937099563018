import React from "react";
import HomeCarousel from "../../components/carousel/HomeCarousel";
import ProductCarousel from "../../components/homeproductcarousel/HomeProductCarousel";
import { tiles } from "../../../data_old/Tiles";
import ContactUsForm from "../../components/contactUsForm/contactUsForm";

const HomePage = () => {
    return (
        <div className="">
          <HomeCarousel />

          <div className="mt-5">
            <span className="ml-auto flex items-center justify-center mt-10 text-4xl font-large text-gray-700 group-hover:text-gray-800">
                FEATURED PRODUCTS
            </span>
          </div>
    
          <div className="space-y-10 py-20">
            <ProductCarousel data={tiles} />
            {/* <ProductCarousel data={tiles} /> */}
          </div>

          <div className="space-y-7">
            <ContactUsForm/>
          </div>
    
          
        </div>
      );
}

export default HomePage