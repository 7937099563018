import { useState } from "react";
import { Typography } from "@mui/material";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { Fragment } from "react";
import "./CreateProductForm.css";
import { useDispatch } from "react-redux";
import { createProduct } from "../../../redux/components/product/Action";
import { useNavigate } from "react-router-dom";
import FormData from "form-data";


const initialSizes = [
  { name: "8*4 ft", quantity: 0 },
  // { name: "M", quantity: 0 },
  // { name: "L", quantity: 0 },
];

const CreateProductForm = () => {
  
const [productData, setProductData] = useState({
  imageUrl: null,
  images: [],
  zipFile: null,
  brand: "",
  title: "",
  color: "",
  finish: "",
  look: "",
  design: "",
  length: "",
  width: "",
  packageSize: "",
  application: "",
  baseMaterial: "",
  projectType: "",
  discountedPrice: "",
  price: "",
  discountPersent: "",
  size: initialSizes,
  quantity: "",
  topLavelCategory: "",
  secondLavelCategory: "",
  thirdLavelCategory: "",
  description: "",
});

const dispatch=useDispatch();
const navigate = useNavigate();
const jwt=localStorage.getItem("jwt")

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    setProductData({ ...productData, imageUrl: e.target.files[0] });
  };
  
  const handleMultipleFileChange = (e) => {
    const files = e.target.files;
    const imagesArray = Array.from(files);
    setProductData({ ...productData, images: imagesArray });
  };
  
  const handleZipFileChange = (e) => {
    setProductData({ ...productData, zipFile: e.target.files[0] });
  };

  const handleSizeChange = (e, index) => {
    let { name, value } = e.target;
    name==="size_quantity"?name="quantity":name=e.target.name;

    const sizes = [...productData.size];
    sizes[index][name] = value;
    setProductData((prevState) => ({
      ...prevState,
      size: sizes,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('imageUrl', productData.imageUrl);
    productData.images.forEach((image, index) => {
      formData.append(`images[]`, image);
    });
    formData.append('zipFile', productData.zipFile);
    formData.append('brand', productData.brand);
    formData.append('title', productData.title);
    formData.append('color', productData.color);
    formData.append('finish', productData.finish);
    formData.append('look', productData.look);
    formData.append('design', productData.design);
    formData.append('length', productData.length);
    formData.append('width', productData.width);
    formData.append('packageSize', productData.packageSize);
    formData.append('application', productData.application);
    formData.append('baseMaterial', productData.baseMaterial);
    formData.append('projectType', productData.projectType);
    formData.append('discountedPrice', productData.discountedPrice);
    formData.append('price', productData.price);
    formData.append('discountPersent', productData.discountPersent);
    formData.append('size', productData.size);
    formData.append('quantity', productData.quantity);
    formData.append('topLavelCategory', productData.topLavelCategory);
    formData.append('secondLavelCategory', productData.secondLavelCategory);
    formData.append('thirdLavelCategory', productData.thirdLavelCategory);
    formData.append('description', productData.description);

    dispatch(createProduct({data:formData,jwt}))
    console.log(formData);
    navigate(`/admin/products`)
  };


  return (
    <Fragment className="createProductContainer">
      <Typography
        variant="h3"
        sx={{ textAlign: "center", bgcolor:"rgb(0, 0, 22)" }}
        className="py-10 text-center "
      >
        Add New Product
      </Typography>
      <form
        onSubmit={handleSubmit}
        encType="multipart/form-data"
        className="createProductContainer min-h-screen"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} >
            <label htmlFor="imageInput">Upload Image : </label>
            <input
              type="file"
              accept="image/*"
              name="imageUrl"
              onChange={handleFileChange}
            />
          </Grid>
          <Grid item xs={12} >
            <label htmlFor="imageInput">Upload Multiple Images : </label>
            <input
              type="file"
              multiple
              accept="image/*"
              name="images"
              onChange={handleMultipleFileChange}
            />
          </Grid>
          <Grid item xs={12}>
            <label htmlFor="imageInput">Upload Zip File : </label>
            <input
              type="file"
              accept=".zip"
              name="zipFile"
              onChange={handleZipFileChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Brand"
              name="brand"
              value={productData.brand}
              onChange={handleChange}
            />
          </Grid>
        
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Title"
              name="title"
              value={productData.title}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Color"
              name="color"
              value={productData.color}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Finish"
              name="finish"
              value={productData.finish}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Look"
              name="look"
              value={productData.look}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Design"
              name="design"
              value={productData.design}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Length"
              name="length"
              value={productData.length}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Width"
              name="width"
              value={productData.width}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Package Size"
              name="packageSize"
              value={productData.packageSize}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Application"
              name="application"
              value={productData.application}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Base Material"
              name="baseMaterial"
              value={productData.baseMaterial}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Project Type"
              name="projectType"
              value={productData.projectType}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Quantity"
              name="quantity"
              value={productData.quantity}
              onChange={handleChange}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Price"
              name="price"
              value={productData.price}
              onChange={handleChange}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Discounted Price"
              name="discountedPrice"
              value={productData.discountedPrice}
              onChange={handleChange}
              type="number"
            />
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Discount Percentage"
              name="discountPersent"
              value={productData.discountPersent}
              onChange={handleChange}
              type="number"
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <FormControl fullWidth>
              <InputLabel>Top Level Category</InputLabel>
              <Select
                name="topLavelCategory"
                value={productData.topLavelCategory}
                onChange={handleChange}
                label="Top Level Category"
              >
                <MenuItem value="laminates">Laminates</MenuItem>
                <MenuItem value="flooring">Flooring</MenuItem>
                <MenuItem value="kitchen">Kitchen</MenuItem>
                <MenuItem value="wardrobe">WardRobe</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={4}>
            <FormControl fullWidth>
              <InputLabel>Second Level Category</InputLabel>
              <Select
                name="secondLavelCategory"
                value={productData.secondLavelCategory}
                onChange={handleChange}
                label="Second Level Category"
              >
                <MenuItem value="shop_by_look">Shop by Look - Laminates</MenuItem>
                <MenuItem value="shop_by_look_1">Shop by Look - Flooring</MenuItem>
                <MenuItem value="shop_by_finish">Shop by Finish</MenuItem>
                <MenuItem value="counter_tops">Counter Tops</MenuItem>
                <MenuItem value="wardrobe_look">WardRobe Look</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={4}>
            <FormControl fullWidth>
              <InputLabel>Third Level Category</InputLabel>
              <Select
                name="thirdLavelCategory"
                value={productData.thirdLavelCategory}
                onChange={handleChange}
                label="Third Level Category"
              >
                <MenuItem value="wood_laminates">Wood Laminates</MenuItem>
                <MenuItem value="marble_and_stone_laminates">Marble and Stone Laminates</MenuItem>
                <MenuItem value="high_gloss">High Gloss</MenuItem>
                <MenuItem value="matte_gloss">Matte Gloss</MenuItem>
                <MenuItem value="suede_finish">Suede Finish</MenuItem>
                <MenuItem value="granite">Granite</MenuItem>
                <MenuItem value="granite_slabs">Granite Slabs</MenuItem>
                <MenuItem value="fluted_laminates">Fluted Laminates</MenuItem>
                <MenuItem value="solid_laminates">Solid Laminates</MenuItem>
                <MenuItem value="marble_look">Marble Look</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="outlined-multiline-static"
              label="Description"
              multiline
              name="description"
              rows={3}
              onChange={handleChange}
              value={productData.description}
            />
          </Grid>
          {productData.size.map((size, index) => (
            <Grid container item spacing={3} >
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Size Name"
                  name="name"
                  value={size.name}
                  onChange={(event) => handleSizeChange(event, index)}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Quantity"
                  name="size_quantity"
                  type="number"
                  onChange={(event) => handleSizeChange(event, index)}
                  required
                  fullWidth
                />
              </Grid> 
            </Grid>
            
          ))}
          <Grid item xs={12} >
            <Button
              variant="contained"
              sx={{ p: 1.8 }}
              className="py-20"
              size="large"
              type="submit"
            >
              Add New Product
            </Button>
            
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};

export default CreateProductForm;
