import React, { useState } from 'react';
import './contactUsForm.css';
import { Grid, Snackbar, Alert } from '@mui/material';
import contact from "./contact.png";
import { Typography } from "@mui/material";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Fragment } from "react";
import { useDispatch } from 'react-redux';
import { sendEmail } from '../../../redux/components/contactForm/Action';

function ContactUsForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const dispatch = useDispatch();
  const jwt=localStorage.getItem("jwt")

  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

    // Validate on change
    setErrors({
      ...errors,
      [name]: validateField(name, value)
    });
  };

  const validateField = (name, value) => {
    switch (name) {
      case 'name':
        return value ? '' : 'Name is required';
      case 'email':
        return value
          ? /\S+@\S+\.\S+/.test(value)
            ? ''
            : 'Email address is invalid'
          : 'Email is required';
      case 'message':
        return value ? '' : 'Message is required';
      default:
        return '';
    }
  };

  const validateForm = () => {
    const validationErrors = {};
    Object.keys(formData).forEach((key) => {
      const error = validateField(key, formData[key]);
      if (error) {
        validationErrors[key] = error;
      }
    });
    return validationErrors;
  };

  const handleSubmit = (e) => {

    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {

      const form = new FormData();
      form.append('name', formData.name);
      form.append('email', formData.email);
      form.append('message', formData.message);

      dispatch(sendEmail({data:form}))
        .then(() => {
          setSnackbarOpen(true);  // Show Snackbar on successful email sending
          setIsSubmitted(true);
        })
        .catch(error => {
          console.error("Error sending email: ", error);
        });
      console.log(form);

    } else {
      setErrors(validationErrors);
    }
  };

  return (

    // <Fragment className="">
    //   <Typography
    //     variant="h3"
    //     sx={{ textAlign: "center" }}
    //     className="py-10 text-center "
    //   >
    //     Contact Us
    //   </Typography>
    //   <form
    //     onSubmit={handleSubmit}
    //     encType="multipart/form-data"
    //     className=""
    //   >
    //     <Grid container spacing={2} justifyContent="center" alignItems="center">
    //       <Grid item xs={8}>
    //         <TextField
    //           fullWidth
    //           label="Name"
    //           name="name"
    //           placeholder="Enter your Name"
    //           value={formData.name}
    //           onChange={handleChange}
    //         />
    //       </Grid>
        
    //       <Grid item xs={8}>
    //         <TextField
    //           fullWidth
    //           label="Email"
    //           type="email"
    //           name="email"
    //           placeholder="Enter a valid email address"
    //           value={formData.email}
    //           onChange={handleChange}
    //         />
    //       </Grid>
    //       <Grid item xs={8}>
    //         <TextField
    //           label="Message"
    //           fullWidth
    //           multiline
    //           name="message"
    //           placeholder="Enter your message"
    //           value={formData.message}
    //           onChange={handleChange}
    //         />
    //       </Grid>
    //       <Grid item xs={12}  display="flex" justifyContent="center">
    //         <Button
    //           variant="contained"
    //           sx={{ p: 1.8 }}
    //           className="py-20"
    //           size="large"
    //           type="submit"
    //         >
    //           Contact Us
    //         </Button> 
    //       </Grid>
    //     </Grid>
    //   </form>
    // </Fragment>

    <div className="contact-form-container">
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <div className="">
                    <img src={contact} alt="Contact Us" />
                </div>
            </Grid>

            <Grid item xs={12} sm={6}>
                <div className="contact-form">
                    <h2>Contact Us</h2>
                    {isSubmitted ? (
                    <p>Thank you for your message! We will get back to you soon.</p>
                    ) : (
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <input
                                type="text"
                                name="name"
                                placeholder="Enter your Name"
                                value={formData.name}
                                onChange={handleChange}
                                className={errors.name ? 'error-input' : ''}
                            />
                            {errors.name && <p className="error">{errors.name}</p>}
                        </div>
                        <div className="form-group">
                            <input
                                type="email"
                                name="email"
                                placeholder="Enter a valid email address"
                                value={formData.email}
                                onChange={handleChange}
                                className={errors.email ? 'error-input' : ''}
                            />
                            {errors.email && <p className="error">{errors.email}</p>}
                        </div>
                        <div className="form-group">
                            <textarea
                                name="message"
                                placeholder="Enter your message"
                                value={formData.message}
                                onChange={handleChange}
                                className={errors.message ? 'error-input' : ''}
                            />
                            {errors.message && <p className="error">{errors.message}</p>}
                        </div>
                        <div className='flex justify-center'>
                          <button type="submit">
                            SUBMIT
                          </button>
                        </div>
                    </form>
                    )}
                </div>
            </Grid>
        </Grid>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert onClose={() => setSnackbarOpen(false)} severity="success">
            Email sent successfully!
          </Alert>
        </Snackbar>
    </div>
  );
}

export default ContactUsForm;
