import React from 'react';
import './Brands.css';
import { Button } from '@mui/material';
import about from "./Mask group.png";
import unique_value from "./our unique value proposition.png";
import showcase from "./showcase your products globally.png";
import engage from "./engage and inspire.png";
import audience from "./who makes up adm aurdienece.png";
import join from "./join us 3 steps.png";
import real from "./real time.png";
import { useNavigate } from 'react-router-dom';

const BrandsPage = () => {

  const navigate = useNavigate();

  return (

    <div className='font-custom'>

    {/* Product info */}
    <div className="mb-20">
      <div className="flex justify-center mt-10">
        <h1 className="text-2xl lg:text-6xl text-gray-900">Welcome to ArchDesign</h1><strong className='text-2xl lg:text-6xl text-gray-900'>Mart</strong>
      </div>

      <div className="flex justify-center ml-40 mr-40 mt-7">
        <p className='text-center text-xl'>Elevate your design journey with ArchDesignMart, where creativity meets tech and convenience.
        <br></br>
          Partner with us to showcase your high-quality materials and reach a global audience of design professionals.</p>
      </div>

      <div className="mt-4 flex justify-center mt-9">
        
        <div className='flex space-x-5 items-center text-lg lg:text-xl text-gray-900'>
            <Button
              onClick={() => navigate("/contact")}
              variant="contained"
              sx={{ p: 1.8 }}
              className="py-20"
              size="medium"
              type="submit"
            >
              Contact Us
            </Button>
        </div>

        <div className='flex space-x-5 items-center text-lg lg:text-xl text-gray-900 ml-9'>
            <Button
              onClick={() => navigate("/book/demo")}
              variant="contained"
              sx={{ p: 1.8 }}
              className="py-20"
              size="medium"
              type="submit"
            >
              Book a Demo
            </Button>
        </div>
      </div>
    </div>

    <section className='grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-10 px-4 pt-10 mb-20'>

        {/* Image gallery */}
        <div className="flex flex-col items-center">
          <img
            src={about}
            alt="ADM"
            className="h-full w-full object-cover object-center"
          />
        </div>

        {/* Product info */}
        <div className="lg:col-span-1 maxt-auto max-w-2xl px-4 pb-16 sm:px-6 lg:max-w-7xl lg:px-8 lg:pb-24">
          <div className="lg:col-span-2">
            <h1 className="text-xl lg:text-4xl font-semibold text-gray-900">About ArchDesignMart</h1>
            {/* <h1 className='text-lg lg:text-xl text-gray-900 opacity-60 pt-1'>
              ADM
            </h1> */}
          </div>

          {/* Options */}
          <div className="mt-4 lg:row-span-3 lg:mt-0">
            
            <div className='flex space-x-5 items-center text-lg lg:text-xl text-gray-900 mt-6 mr-10' style={{ textAlign: 'justify' }}>
            ArchDesignMart is a premier marketplace for architects, interior designers, and homeowners. 
            <br></br>
                We offer a meticulously curated collection of high-quality PBR materials, textures, and furniture items 
                that can be used in the design process and then order to your doorsteps.
            </div>
          </div>
        </div>

    </section>

    <section className='grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-10 px-4 pt-10 mb-20'>

      {/* Product info */}
    <div className="lg:col-span-1 maxt-auto max-w-2xl px-4 pb-16 sm:px-6 lg:max-w-7xl lg:px-8 lg:pb-24">
      <div className="lg:col-span-2">
        <h1 className="text-xl lg:text-4xl font-semibold text-gray-900">Our Unique Value Proposition</h1>
        {/* <h1 className='text-lg lg:text-xl text-gray-900 opacity-60 pt-1'>
          ADM
        </h1> */}
      </div>

      {/* Options */}
      <div className="mt-4 lg:row-span-3 lg:mt-0">
        
        <div className='flex space-x-5 items-center text-lg lg:text-xl text-gray-900 mt-6' style={{ textAlign: 'justify' }}>
            <ul className=''>
              <li>- Extensive catalog of premium materials</li>
              <li>- Digital downloads for easy integration</li>
              <li>- Global accessibility</li>
              <li>- Inspiration hub with the latest trends</li>
              <li>- Automatic BOQ generation and ordering</li>
            </ul>
        </div>
      </div>
    </div>

    {/* Image gallery */}
    <div className="flex flex-col items-center">
          <img
            src={unique_value}
            alt="ADM"
            className="h-full w-full object-cover object-center"
          />
    </div>

    </section>

    <section className='grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-10 px-4 pt-10 mb-20'>

    {/* Image gallery */}
    <div className="flex flex-col items-center">
          <img
            src={showcase}
            alt="ADM"
            className="h-full w-full object-cover object-center"
          />
    </div>

    {/* Product info */}
    <div className="lg:col-span-1 maxt-auto max-w-2xl px-4 pb-16 sm:px-6 lg:max-w-7xl lg:px-8 lg:pb-24">
      <div className="lg:col-span-2">
        <h1 className="text-xl lg:text-4xl font-semibold text-gray-900">Showcase your products globally!</h1>
        {/* <h1 className='text-lg lg:text-xl text-gray-900 opacity-60 pt-1'>
          ADM
        </h1> */}
      </div>

      {/* Options */}
      <div className="mt-4 lg:row-span-3 lg:mt-0">
        
        <div className='flex space-x-5 items-center text-lg lg:text-xl text-gray-900 mt-6 mr-10' style={{ textAlign: 'justify' }}>
        Gain exposure to a diverse global audience of design professionals and consumers through ArchDesignMart's marketplace. 
        <br></br>
        <br></br>
        Enhance brand visibility and recognition within the design industry by showcasing your products on a prominent platform, and expand your market presence through strategic collaboration with ArchDesignMart.
        </div>
      </div>
    </div>

    </section>

    <section className='grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-10 px-4 pt-10 mb-20'>

      {/* Product info */}
    <div className="lg:col-span-1 maxt-auto max-w-2xl px-4 pb-16 sm:px-6 lg:max-w-7xl lg:px-8 lg:pb-24">
      <div className="lg:col-span-2">
        <h1 className="text-xl lg:text-4xl font-semibold text-gray-900">Engage and inspire designers</h1>
        {/* <h1 className='text-lg lg:text-xl text-gray-900 opacity-60 pt-1'>
          ADM
        </h1> */}
      </div>

      {/* Options */}
      <div className="mt-4 lg:row-span-3 lg:mt-0">
        
        <div className='flex space-x-5 items-center text-lg lg:text-xl text-gray-900 mt-6'>
        Designers seek inspiration, not sales pitches.
        <br></br>
        Market your products to professionals during their exploration, selection, and design phases.
        <br></br>
        <br></br>
        Our marketplace enables you to connect with relevant designers by directly integrating into their projects during the design process and transitioning them to your sales team, boosting your chances of winning the project and building lasting relationships with the design community.
        </div>
      </div>
    </div>

    {/* Image gallery */}
    <div className="flex flex-col items-center">
          <img
            src={engage}
            alt="ADM"
            className="h-full w-full object-cover object-center"
          />
    </div>

    </section>

    <section className='grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-10 px-4 pt-10 mb-20'>

    {/* Image gallery */}
    <div className="flex flex-col items-center">
          <img
            src={audience}
            alt="ADM"
            className="h-full w-full object-cover object-center"
          />
    </div>

    {/* Product info */}
    <div className="lg:col-span-1 maxt-auto max-w-2xl px-4 pb-16 sm:px-6 lg:max-w-7xl lg:px-8 lg:pb-24">
      <div className="lg:col-span-2">
        <h1 className="text-xl lg:text-4xl font-semibold text-gray-900">Who Makes Up the ADM Audience?</h1>
        {/* <h1 className='text-lg lg:text-xl text-gray-900 opacity-60 pt-1'>
          ADM
        </h1> */}
      </div>

      {/* Options */}
      <div className="mt-4 lg:row-span-3 lg:mt-0">
        
        <div className='flex space-x-5 items-center text-lg lg:text-xl text-gray-900 mt-6 mr-10' style={{ textAlign: 'justify' }}>
        On ADM, you'll connect with a diverse group of AEC professionals like construction professionals, including architects, interior designers, engineers, and BIM managers, spanning a broad spectrum of experiences and specializations. 
        <br></br>
        <br></br>
        This audience includes everyone from Genz who just started out to seasoned senior partners at their firms.
        They work in various settings, from small local businesses to some of the world's largest engineering and architecture firms. Each of these professionals has specific targets and goals for their projects.
        </div>
      </div>
    </div>

    </section>

    <section className='grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-10 px-4 pt-10 mb-20'>

      {/* Product info */}
    <div className="lg:col-span-1 maxt-auto max-w-2xl px-4 pb-16 sm:px-6 lg:max-w-7xl lg:px-8 lg:pb-24">
      <div className="lg:col-span-2">
        <h1 className="text-xl lg:text-4xl font-semibold text-gray-900">Real-Time Performance Tracking and Analysis</h1>
      </div>

      {/* Options */}
      <div className="mt-4 lg:row-span-3 lg:mt-0">
        
        <div className='flex space-x-5 items-center text-lg lg:text-xl text-gray-900 mt-6'>

        <ul className=''>
          <li>Get a real-time view of your brand's performance to identify growth trends and plan your next steps.</li>
          <br></br>
          <li>- Monitor your potential reach on ADM.</li>
          <li>- Track audience size.</li>
          <li>- See who is browsing your categories.</li>
          <li>- Engage the right audience with targeted insights.</li>
          <li>- Segment your audience by demographics and behavior.</li>
          <li>- Build meaningful connections.</li>
          <li>- Segment your audience by demographics and behavior.</li>
          <br></br>
          <li>Gain insights on product downloads, competitor performance, and growth opportunities for better decision-making.</li>
        </ul>

        </div>
      </div>
    </div>

    {/* Image gallery */}
    <div className="flex flex-col items-center">
          <img
            src={real}
            alt="ADM"
            className="h-full w-full object-cover object-center"
          />
    </div>

    </section>

    <div className="flex flex-col items-center pl-20 pr-20">
      <img
        src={join}
        alt="ADM"
        className="h-full w-full object-cover object-center"
      />
    </div>

    <div className="mt-4 flex justify-center mt-9">
        
        <div className='flex space-x-5 items-center text-lg lg:text-xl text-gray-900'>
            <Button
              onClick={() => navigate("/contact")}
              variant="contained"
              sx={{ p: 1.8 }}
              className="py-20"
              size="medium"
              type="submit"
            >
              Contact Us
            </Button>
        </div>

        <div className='flex space-x-5 items-center text-lg lg:text-xl text-gray-900 ml-9'>
            <Button
              onClick={() => navigate("/book/demo")}
              variant="contained"
              sx={{ p: 1.8 }}
              className="py-20"
              size="medium"
              type="submit"
            >
              Book a Demo
            </Button>
        </div>
      </div>

    </div>

  );
};

export default BrandsPage;