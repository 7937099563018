import React from 'react'
import "./ProductCard.css"
import { useNavigate } from 'react-router-dom'
import map from './map4.jpeg'
import { Avatar } from '@mui/material'

const ProductCard = ({product}) => {
    const BASE_URL = 'https://archdesignmart.com/api/';

    const navigate = useNavigate()

  return (
    <div onClick={()=> navigate(`/api/products/id/${product.id}`)} className='productCard w-[18rem] m-3 transition-all cursor-pointer'>

        <Avatar 
            className='h-[20rem] mt-4 mb-4'
            imageProps={{resizeMode: 'cover'}}
            sx={{
                bgcolor: "white",
                color: "white",
                cursor: "pointer",
                height: "298px",
                width: "290px",
            }}>
            <div className='h-[20rem]'>
                <img className='h-full w-full object-cover ' src={`${BASE_URL}${product.imageUrl.replace('src/main/resources/static/', '')}`} alt="map" />
            </div>
        </Avatar>

        <div className='textPart bg-white p-3'>
            <div>
                <p className='font-bold opacity-60'>{product.brand}</p>
                <p>{product.title}</p>
            </div>
            <span></span>
                <span></span>
            <div className='flex items-center space-x-2'>
                {product?.category?.parentCategory?.parentCategory?.name === 'laminates' &&
                    <p className='font-semibold'>₹{((product.discountedPrice) / ((product.length || 0) * (product.width || 0)))} /sq. ft.</p>
                }

                {product?.category?.parentCategory?.parentCategory?.name === 'flooring' &&
                    <p className='font-semibold'>₹{(((product.discountedPrice) / ((product.length || 0) * (product.width || 0))) * 46450).toFixed(2)} /sq. ft.</p>
                }
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                
                {product?.category?.parentCategory?.parentCategory?.name === 'laminates' &&
                    <p className='font-semibold'>₹{product.discountedPrice} /Sheet</p>
                }

                {product?.category?.parentCategory?.parentCategory?.name === 'flooring' &&
                    <p className='font-semibold'>₹{product.discountedPrice} /Box</p>
                }
            </div>
            <div className='flex items-center space-x-2'>
                <p className='line-through opacity-50'>₹{product.price}</p>
                <p className='text-green-600 font-semibold'>{product.discountPersent}% OFF</p>
            </div>
        </div>

    </div>
  )
}

export default ProductCard