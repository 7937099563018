import React, { useEffect } from 'react'
import CartItem from './CartItem'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { getCart } from '../../../redux/components/cart/Action'
import { useDispatch, useSelector } from 'react-redux'

const Cart = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jwt = localStorage.getItem("jwt");

  const {cart} = useSelector(store=>store);

  const handleSubmit = () => {
    navigate("/checkout?step=2")
  }

  useEffect(() => {
    dispatch(getCart(jwt));
  }, [jwt, cart.updateCartItem, cart.deleteCartItem]);

  return (
    <div>

      <div className='lg:grid grid-cols-3 lg:px-16 relative'>
        <div className='col-span-2'>
            {cart.cartItems.map((item)=> <CartItem item={item} showButton={true} />)}
        </div>

        <div className='px-5 sticky top-0 h-[100vh] mt-5 lg:mt-0'>

            <div className='border'>
              <p className="font-bold opacity-60 pb-4">PRICE DETAILS</p>
              <hr />

              <div className="space-y-3 font-semibold">
                <div className="flex justify-between pt-3 text-black ">
                  <span>Price ({cart.cart?.totalItem} item)</span>
                  <span>₹{cart.cart?.totalPrice}</span>
                </div>
                <div className="flex justify-between">
                  <span>Discount</span>
                  <span className="text-green-700">-₹{cart.cart?.discounte}</span>
                </div>
                <div className="flex justify-between">
                  <span>Delivery Charges</span>
                  <span className="text-green-700">Free</span>
                </div>
                <hr />
                <div className="flex justify-between font-bold text-lg">
                  <span>Total Amount</span>
                  <span className="text-green-700">₹{cart.cart?.totalDiscountedPrice}</span>
                </div>
              </div>

              <Button onClick={handleSubmit} variant="contained" sx={{ padding: ".8rem 2rem", marginTop: "2rem", width: "100%" }}>
                CheckOut
              </Button>
            </div>

        </div>
        
      </div>

        
    </div>
  )
}

export default Cart