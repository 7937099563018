import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import AddressCard from "../addresscard/AddressCard";
import CartItem from "../cart/CartItem";
import { useDispatch, useSelector } from "react-redux";
import { getOrderById } from "../../../redux/components/order/Action";
import { store } from "../../../redux/Store";
import { createPayment } from "../../../redux/components/payment/Action";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';

const OrderSummary = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const orderId = searchParams.get("order_id");
  const dispatch=useDispatch();
  const jwt=localStorage.getItem("jwt");
  const {order} = useSelector(store=>store)

  const [showMessage, setShowMessage] = useState(false);

// console.log("orderId ", order.order)

useEffect(()=>{
  dispatch(getOrderById(orderId))
},[orderId])

const handleCreatePayment=()=>{
  console.log("payment")
  // dispatch(createPayment(orderId))
  setShowMessage(true);
}
  

  return (
    <div className="space-y-5">
        <div className="p-5 shadow-lg rounded-md border ">
            <AddressCard address={order.order?.shippingAddress}/>
        </div>

        <div>

      <div className='lg:grid grid-cols-3 relative'>
        <div className='col-span-2'>
            {order.order?.orderItems?.map((item)=> <CartItem item={item}  showButton={false}/>)}
        </div>

        <div className='px-5 sticky top-0 h-[100vh] mt-5 lg:mt-0'>

            <div className='border'>
              <p className="font-bold opacity-60 pb-4">PRICE DETAILS</p>
              <hr />

              <div className="space-y-3 font-semibold">
                <div className="flex justify-between pt-3 text-black ">
                  <span>Price ({order.order?.totalItem} item)</span>
                  <span>₹{order.order?.totalPrice}</span>
                </div>
                <div className="flex justify-between">
                  <span>Discount</span>
                  <span className="text-green-700">-₹{order.order?.discounte}</span>
                </div>
                <div className="flex justify-between">
                  <span>Delivery Charges</span>
                  <span className="text-green-700">Free</span>
                </div>
                <hr />
                <div className="flex justify-between font-bold text-lg">
                  <span>Total Amount</span>
                  <span className="text-green-700">₹{order.order?.totalDiscountedPrice}</span>
                </div>
              </div>

              <Button
                onClick={handleCreatePayment}
                variant="contained"
                sx={{ padding: ".8rem 2rem", marginTop: "2rem", width: "100%" }}
              >
                Payment
              </Button>
              {showMessage && (
                <div className="error-message mt-8">
                  <p>For further details, please connect with ADM on </p>
                  <div className="flex items-center mt-5">
                    <WhatsAppIcon></WhatsAppIcon>
                    <span></span>
                    <p className="ml-5">(+91) - 6284063429</p>
                  </div>
                  <div className="flex items-center mt-5">
                    <EmailIcon></EmailIcon>
                    <span></span>
                    <p className="ml-5">archhdesignmart@gmail.com</p>
                  </div>
                </div>
              )}
            </div>

        </div>
        
      </div>

        
    </div>
    </div>
  );
};

export default OrderSummary;
