import axios from 'axios';
import api, { API_BASE_URL } from '../../../config/ApiConfig';
import { CREATE_PAYMENT_FAILURE, CREATE_PAYMENT_REQUEST, CREATE_PAYMENT_SUCCESS, UPDATE_PAYMENT_FAILURE, UPDATE_PAYMENT_REQUEST, UPDATE_PAYMENT_SUCCESS } from './ActionTypes';
  
export const createPayment = (orderId) => async (dispatch) => {

    dispatch({ type: CREATE_PAYMENT_REQUEST });

    try {
        const { data } = await api.post(`/api/payments/${orderId}`, {});

        console.log("datta", data)

        if (data.payment_link_url) {
            window.location.href = data.payment_link_url;
        }
    } catch (error) {
        dispatch({ type: CREATE_PAYMENT_FAILURE, payload: error.message });
    }
};

export const updatePayment = (reqData) => {
    return async (dispatch) => {
      console.log("update payment reqData ",reqData)
      dispatch({ type: UPDATE_PAYMENT_REQUEST });
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${reqData.jwt}`,
          },
        };
        const response = await axios.get(`${API_BASE_URL}/api/payments?payment_id=${reqData.paymentId}&order_id=${reqData.orderId}`,config);
        console.log("updated data",response.data)
        dispatch({
            type: UPDATE_PAYMENT_SUCCESS,
            payload: response.data,
        });
      } catch (error) {
        dispatch({
            type: UPDATE_PAYMENT_FAILURE,
            payload: error.message,
        });
      }
    };
  };

 
  